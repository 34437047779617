h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-weight: 500;
}

h2 {
  font-weight: 400;
}

.hidden {
  display: none;
}

.mg-text-warning {
  color: #999;
  font-style: italic;
}
