
.blog-browser .post{
    margin-bottom:0.8rem;
}

.blog-browser .post h1{
    font-size:1.5rem;
    display: inline;
    margin-right: 1rem;
}

.blog-content{
    margin: 0.4rem 0;
}

