.home-slideshow h1 {
  text-align: center;
  margin-bottom: 4px;
  color: red;
  font-size: 1.35rem;
  font-weight: 400;
}

.home-slideshow h1 .emphasized {
  font-size: 160%;
  font-weight: 700;
}

.slideshow {
  max-height: 350px;
  overflow: hidden;
  position: relative;
}

.slideshow .slide {
  left: 0;
  opacity: 0;
  width: 100%;
  position: absolute;
  transition: opacity 0.5s linear;
}

.slideshow .slide.current-slide {
  opacity: 1;
  position: static;
}
