.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.bodyContainer{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 1rem;
}

@media (min-width: 900px){
  .bodyContainer{
    margin: 0.5rem 0 1rem;
    flex-direction: row;
  }

  .body{
    flex: 5;
  }

  .bar{
    flex: 2;
  }

  .leftBar, .rightBar{
    composes: bar;
  }
}