.top-nav {
  border-bottom: 1px solid #888;
  box-shadow: 0 1px 2px #888;
  padding: 0.3rem 1rem 0.8rem;
  margin-bottom: 0.4rem;
}

.top-nav .mobile-button {
  display: none;
}

.top-nav .brand-icon {
  max-height: 3.5rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.3rem;
}

.top-nav .brand-icon:hover{
  background: transparent;
}

.top-nav .brand-description {
  display: inline-block;
  vertical-align: bottom;
  padding-bottom: 0;
  margin-right: 2rem;
}

.top-nav-links {
  display: inline;
}

.top-nav .brand-description .bottom-line {
  font-weight: 700;
  font-size: 1.2rem;
}

.top-nav .category {
  display: inline-block;
  vertical-align: bottom;
  margin-right: 2rem;
}

.top-nav .category h2 {
  margin-bottom: 0;
  padding: 0.5rem 0.4rem;
  border-radius: 5px;
}

.top-nav .category ul {
  display: none;
  position: absolute;
  list-style: none;
  padding-left: 0;
  padding: 0.4rem 0;
  min-width: 10rem;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0;
  z-index: 2;
}

.top-nav .category .links-container {
  padding-top: 0.2rem;
}

.top-nav .category .link {
  text-decoration: none;
  font-size: 1.2rem;
  display: block;
  padding: 0.15rem 1rem;
  transition: background 0.2s ease;
}

.top-nav .category .link:hover {
  background: rgb(220, 229, 244);
}

@media (min-width: 860px) {
  .top-nav .category:hover h2 {
    background-color: darkgreen;
    color: white;
  }

  .top-nav .category:hover ul {
    display: block;
  }
}

@media (max-width: 859px) {
  .top-nav .brand-description {
    display: none;
  }

  .top-nav .mobile-button {
    display: block;
    float: right;
    font-size: 1.3rem;
    padding: 0.2rem 0.4rem;
    margin-top: 0.4rem;
    border-radius: 5px;
  }

  .top-nav.expanded-mobile .mobile-button {
    background: #ccc;
  }

  .top-nav-links {
    display: none;
    margin-top: 0.5rem;
  }

  .top-nav.expanded-mobile .top-nav-links {
    display: block;
  }

  .top-nav .top-nav-links .category {
    display: block;
  }

  .top-nav .top-nav-links .category ul {
    position: static;
  }

  .top-nav .category.active-category h2{
    background-color: darkgreen;
    color: white;
  }

  .top-nav .category.active-category ul {
    display: block;
  }
}
