.footer {
  background: #0f5c15;
  color: white;
  border-top: 1px solid #001a01;
  padding: 0.5rem 0 1rem;
}

.link {
  display: block;
}

.contactInformation {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}

.contactInformation section {
  max-width: 15em;
  margin: 0 4rem;
}

.copyright {
  margin-top: 2rem;
  text-align: center;
}

@media (max-width: 860px) {
  .footer .category {
    display: none;
  }
}

@media (max-width: 640px) {
  .contactInformation {
    flex-direction: column;
  }

  .contactInformation section {
    max-width: 100%;
    margin-bottom: 0.5rem;
  }
}
