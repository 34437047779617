.root{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 0.75rem;
}

.root img {
    width: 700px;
}
