.root{
    border: 1px solid darkgreen;
    padding: 1em;
}

.topBar{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.preview{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 160px;
    margin: 8px;
}

.previewContainer{
    display: flex;
    flex-wrap: wrap;
    margin-top: 4px;
    min-height: 140px;
    max-height: 600px;
    overflow-y: auto;
    border: 5px solid #ccc;
}

.previewContainer.dropHovered{
    border-color: black;
}

.dropHint{
    color: #999;
    display: flex;
    font-size: 1.25rem;
    align-items: center;
    justify-content: center;
    width:100%;
}

.dropHovered .dropHint{
    color: black;
}

.preview .fileName{
    font-size: 0.8rem;
    margin-bottom: 4px;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.preview .status{
    margin-top: 4px;
    font-size: 0.8rem;
}

.preview :global(.fiv-viv).icon{
    font-size: 150px;
}

.preview img.icon{
    max-width: 150px;
    max-height: 150px;
}