.errorList{
    background: white;
    display: flex;
    flex-direction: column;
    border: 2px solid darkred;
    width: 20rem;
    max-height: 15rem;
    overflow-y: auto;
}

.item{
    border-bottom: 1px solid #ccc;
    padding: 4px;
}

.item:last-of-type{
    border-bottom: 0;
}

.header{
    background: darkred;
    color: white;
    font-weight: bold;
    padding: 4px;
}