.news-list h1, .donation-info h1{
    border-bottom: 2px solid darkgreen;
    margin-bottom: 8px;
}

.news-list ul{
    list-style:none;
    padding-left:0;
}

.news-description{
    margin-left: 4px;
}

