.mg-button {
  border: 1px solid rgb(33, 81, 35);
  background: white;
  border-radius: 3px;
  padding: 2px 6px;
  color: rgb(33, 81, 35);
  font-size: 95%;
  box-shadow: 0 0 2px;
  transition: background 0.3s linear;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.mg-button.mg-button-small {
  padding: 2px;
  font-size: 90%;
  box-shadow: 0 0 1px;
}

.mg-button.disabled {
  border-color: #aaa;
  color: #aaa;
}

.mg-button:not(.disabled):hover {
  background: rgb(157, 209, 148);
  cursor: pointer;
}

.mg-create-button:before {
  content: '+';
  font-weight: 700;
  font-size: 1.3rem;
  padding-right: 0.3rem;
}

.mg-submit-button {
  background: rgb(211, 249, 204);
}

.mg-submit-button:before{
  content: '\2713';
  font-weight: 700;
  font-size: 1.3rem;
  padding-right: 0.3rem;
}

.mg-return-button:before{
  content: '\2717';
  font-weight: 700;
  font-size: 1.3rem;
  padding-right: 0.3rem;
}

.mg-action-button {
  height: 32px;
  width: 32px;
}
