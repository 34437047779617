.item {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  text-align: center;
  margin: 1rem 0;
}

.item img.photo {
  border: 2px solid #ccc;
  box-shadow: 0 0 4px 2px #ccc;
}

.folder{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 80%;
  position: relative;
}

.folder:hover{
  cursor: pointer;
  background: lightsteelblue;
}

.folder.hovered{
  background: lightsteelblue;
}

.folderHoverMenu{
  display: none;
  position: absolute;
  padding-top: 8px;
  top: 100%;
  width: 100%;
  z-index: 1;
}

.folderHoverMenu ul{
  background: white;
  border-radius: 4px;
  border: solid 1px lightsteelblue;
  padding: 0;
  margin: 0;
  list-style: none;
}

.folderHoverMenu li{
  padding: 4px 8px;
}

.folderHoverMenu li:hover{
  background: lightsteelblue;
}

.folder:hover .folderHoverMenu{
  display: block;
}

@media (min-width: 600px) {
  .item {
    width: 50%;
  }
}

@media (min-width: 1000px) {
  .item {
    width: 33%;
  }
}

.dndRoot{
  position: relative;
}


.loading{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.folders{
  background: white;
  position: sticky;
  top: 0;
  z-index: 1;
}