.bios{
    display: flex;
    flex-wrap: wrap;
}

.profile{
    width: 100%;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    margin-bottom:0.4rem;
}

.profile.focused{
    align-items: center; 
    display: flex;
    flex-direction: column;
    order: -1;
    width: 100%;
    margin-bottom: 2rem;
}

.name{
    display: flex;
    align-items: center;
}

.expander{
    margin-left: 4px;
}

.educationTitle{
    margin-top: 4px;
}

.focused .subsection{
    margin-left: 1rem;
}

.profile h2{
    font-weight: 1.1rem;
}

.profile .jobTitle{
    color: white;
    font-weight: bold;
    background: darkgreen;
    padding: 6px;
    border-radius: 4px;
}


@media(min-width: 600px){
    .profile{
        width: 50%;
    }
}

@media(min-width: 1000px){
   .profile{
       width: 33%;
   }   

   .profile.focused{
       align-items: flex-start;
       flex-direction: row;
   }
}
