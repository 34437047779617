.mg-form-row {
  margin-bottom: 8px;
  display: flex;
}

.mg-form-label {
  vertical-align: top;
  width: 7rem;
  text-align: right;
  padding-right: 1rem;
  display: inline-block;
  flex-shrink: 0;
}

.mg-form-content {
  display: inline-block;
}

.mg-align-left .mg-form-label {
  text-align: left;
}

.mg-form input,
.mg-form textarea {
  min-width: 21rem;
  box-sizing: border-box;
}
