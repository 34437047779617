body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

a{
  text-decoration: none;
}

a:hover{
  background: #d6e7fc;
  text-decoration: underline;
}
