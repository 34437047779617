.mg-table{
    border-spacing: 0;
    width: 100%;
}

.mg-table thead tr th{
    border-bottom: 1px solid black;
}

.mg-table tr.odd{
    background:#eeeeee;
}

.mg-table th{
    text-align:left;
}
.mg-table td{
    margin: 0;
    padding: 0.4rem 0;
}

.mg-table tr td:first-child, .mg-table tr th:first-child{
    padding-left: 0.3rem;
}
