.img-logo-button {
  width: 16px;
  height: 16px;
}

.expandable-photo {
  transition: opacity 0.2s ease-in;
}

.expandable-photo:hover {
  cursor: pointer;
  opacity: 0.75;
}
