.bank {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 1rem;
  top: 1rem;
}

.item {
  background: lightyellow;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
  width: 16rem;
  vertical-align: center;
  z-index: 1;
}

.item.info {
  border: 2px solid darkgrey;
}

.item.error {
  border: 2px solid maroon;
}

.item.success {
  border: 2px solid darkgreen;
}
