.brochure{
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
}

.video {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.5rem 0;
}

.video h1{
    font-size: 1.6rem;
    padding-left: 0.5rem;
    margin-bottom: 0.25rem;
}

.video1, .video2, .video3, .video4, .video5 {
    composes: video;
}

.embeddedVideo {
    width: 560px;
    height: 315px;
    margin: 0 auto;
}