.slideshow_photo_picker {
  display: flex;
  flex-wrap: wrap;
}

.slideshow_photo_picker .item {
  margin: 4px;
  position: relative;
}

.slideshow_photo_picker .item .add_button {
  display: none;
  top: 0;
  right: 0;
  position: absolute;
}

.slideshow_photo_picker .item:hover .add_button {
  display: block;
}

.slideshow_photo_picker .item .disabled{
    filter: grayscale(90%);
}
