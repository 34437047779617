.day-cell{
    min-height:10rem;
    box-sizing: border-box;    
}

.day-cell.dummy{
    background: #eee;
}

.day-cell-event{
    display: block;
}
