.home-page .half{
    width:50%;
    display:inline-block;
    vertical-align: top;
    padding: 0 8px;
    box-sizing: border-box;
}

@media (max-width: 840px){
    .home-page .half{
        width: 100%;
    }
}

@media (min-width: 841px){
    .home-page .left{
        padding-right: 20px;
    }
}

