.root{
    display: flex;
    align-items: center;
}

.root h1{
    font-size: 1.4rem;
    margin: 0;
}

.root .tagLink{
    margin-right: 8px;
}

.root .activeLink::before{
    content: '>> ';
    font-size: 70%;
}

@media(min-width: 900px){
    .root{
        padding-left: 0.5rem;
        flex-direction: column;
        align-items: flex-start;
    }

    .root h1{
        margin-bottom: 0.5rem;
    }
}