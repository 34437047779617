.options-page{
    align-items: stretch;
    display: flex;
}
.options-page-nav{
    align-items: flex-start;
    border-right: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    padding-right: 1rem;
}

.options-page-nav > h2{
    margin-bottom: 0.5rem;
}

.options-page-nav .options-page-link{
    padding: 0.25rem 0;
}

.options-page .options-page-body{
    padding: 0 0.75rem;
}