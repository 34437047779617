.root{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header{
    height: 1.5em;
}

.body{
    display: none;
    position: absolute;
    top: 1.5em;
}

.root:hover .body{
    display: block;
}