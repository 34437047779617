.slideshow_strip {
  display: flex;
  flex-wrap: wrap;
}

.slideshow_strip .item {
  margin: 4px;
  position: relative;
}

.slideshow_strip .item .remove_button {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}

.slideshow_strip .item:hover .remove_button {
  display: block;
}
