.markdown-editor {
  display: flex;
}

.markdown-editor section:first-child {
  margin-right: 1rem;
}

.markdown-editor section {
  display: flex;
  flex-direction: column;
}

.markdown-editor .markdown-editor-box {
  flex-grow: 1;
}

.markdown-editor-preview {
  border: 1px solid #ccc;
  min-height: 8rem;
  min-width: 25rem;
}
