.timelinks {
  display: flex;
  margin-bottom: 0.5rem;
  justify-content: space-between;
}

.timelinks > div{
  max-width: 35%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.uploader{
  margin: 8px 0;
}

.dateDisplay {
  display: inline-block;
  margin-right: 16px;
}