.backdrop {
  background: rgb(100, 100, 100);
  background: rgba(100, 100, 100, 0.9);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  z-index: 10;
}

.viewer {
  border: 1px solid black;
  background: white;
  padding: 4px;
  position: relative;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.1rem;
}

.viewer img{
  max-width: 90vw;
  max-height: 90vh;
}

.noScroll{
  overflow: hidden;
}