.selectable:hover{
    background: #bad5ff;
    cursor: pointer;
}

.activeTag{
    background: #d9e7fd;
    border-radius: 6px;
    margin-right: 6px;
    padding: 4px;
}

.removeTagButton{
    margin-left: 4px;
}

.removeTagButton:hover{
    cursor: pointer;
}

.customTagOption{
    font-style: italic;
    composes: selectable;
}

.completionBox{
    background: white;
    display: block;
    padding: 4px;
    font-size: 0.9rem;
    border: 1px solid black;
    position: absolute;
    z-index: 1;
}

.completionBox.hidden{
    display: none;
}

.appliedTags{
    margin-bottom: 8px;
    font-size: 0.9rem;
}

.tagViewer{
    display: inline-block;
}

.tagViewerLabel{
    color: #888;
    margin-right: 4px;
}