.slideshow_editor {
  display: grid;
  grid-template-areas: 'header header' 'strip saveButton' 'picker picker';
  grid-template-columns: 1fr auto;
}

.slideshow_editor > h1 {
  grid-area: header;
}

.slideshow_editor_strip{
  grid-area: strip;
}

.slideshow_editor_save{
  grid-area: saveButton;
}

.slideshow_editor_strip, .slideshow_editor_save{
  border-bottom: 1px solid #ccc;
  padding-bottom: 4px;
  margin: 8px 0 16px;
}

.slideshow_editor_photopicker{
  grid-area: picker;
}