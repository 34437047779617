.mg-pager{
    align-items: center;
    display: flex;
    margin-top:8px;
}

.mg-pager .current-page{
    margin: 0 6px;
    font-weight: 700;
}
