.root {
}

.title {
  font-size: 1.4rem;
}

.body {
  margin: 0.3rem 0 0.4rem;
}
