.calendar-cells {
  width: 100%;
}

.calendar-cells {
  border-collapse: collapse;
}

.calendar-cells th {
  background: #ccc;
  text-align: left;
}

.calendar-cells td {
  width: 14.2857%;
}

.calendar-cells td,
.calendar-cells th {
  border: 1px solid black;
}

.calendar-cells td .date-print {
  background: #eee;
  display: block;
  margin: 0.1rem;
  padding: 0.1rem;
  box-sizing: border-box;
  text-align: right;
}

.calendar .current-month {
  font-weight: 700;
  margin: 0 6px;
}

.calendar-toolbar{
  align-items: center;
  display: flex;
}
