.modal{
    display: block;
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    min-width: 600px;
    border: 1px solid #ccc;
    background: white;
    box-shadow:0px 0px 4px 4px #333;
    padding: 4px;
    border-radius: 4px;
}

.canvas{
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.7);
    width:100%;
    height:100%;
    position:fixed;
    top:0;
    left:0;
    display: none;
    z-index: 10;
}

.active{
    display: block;
}

.title{
    border-bottom: 1px solid #ccc;
    font-size: 1.3rem;
    padding: 4px;
}

.footer{
    border-top: 1px solid #ccc;
    text-align: right;
    padding: 4px;
}

.body{
    padding: 12px 4px;
}